<!-- (c) 智汇考题 -->
<template>
    <div class="loading">
        <van-loading size="36px" vertical>加载中</van-loading>
    </div>
</template>
<script>
import Vue from 'vue';
import { Loading } from 'vant-green';

Vue.use(Loading);
export default {

}
</script>
<style scoped>
    .loading {
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
    }
</style>
