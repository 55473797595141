<!-- (c) 智汇考题 -->
<template>
    <div id="content" class="container">
        <template v-if="loading && list.length == 0"> 
            <common-loading></common-loading>
        </template>
        <template v-else>
            <nav-header :title="header_title"></nav-header>
            <div class="list">
                <van-cell :title="'得分：'+item.score+'，用时：'+ item.hr+'时'+item.min+'分'+item.sec+'秒'" :label="'状态：'+ item.status_word + ' 时间：'+item.createdAt" is-link center v-for="item in list" :key="item.id" @click="goResult(item.id)"/>
            </div> 
            <div class="drop">
                <img v-show="loading && !over" src="../../assets/img/loading.gif" />
                <span v-if="over">已经到底了</span>
            </div>
        </template>
    </div>
</template>
<script>
import Vue from 'vue';
import { Cell } from 'vant-green';

Vue.use(Cell);

import CommonLoading from "../../components/common-loading.vue";
import NavHeader from "../../components/nav-header.vue";


export default{
    data() {
        return {
            id: this.$route.query.id,
            loading: false,
            pagination: {
                page: 1,
                size: 15
            },
            list: [],
            over: false,
            header_title: '考试记录'
        }
    },
    components: {
        CommonLoading,
        NavHeader
    },
    mounted() {
        window.addEventListener("scroll", this.ScrollToBottomEvt, true);
        this.getData();
        console.log(this.columns)
    },
    beforeDestroy() {
        window.removeEventListener("scroll", this.ScrollToBottomEvt, true);
    },
    methods: {
        getData(more = false) {
            if (this.over) {
                return;
            }
            if (this.loading) {
                return;
            }
            if (more) {
                this.pagination.page++;
            }
            this.loading = true;
            console.log(this.pagination)
            this.$api.Exam.PaperRecord(this.id, this.pagination).then((res) => {
                this.loading = false;
                let list = res.data.records;
                list.forEach((item) => {
                    const end = parseInt(Date.parse(new Date(item.submitAt.replace(/-/g, "/"))));
                    const now = parseInt(Date.parse(new Date(item.createdAt.replace(/-/g, "/"))));
                    const msec = end - now;
                    let day = parseInt(msec / 1000 / 60 / 60 / 24);
                    let hr = parseInt((msec / 1000 / 60 / 60) % 24);
                    let min = parseInt((msec / 1000 / 60) % 60);
                    let sec = parseInt((msec / 1000) % 60);
                    item.hr = hr > 9 ? hr : "0" + hr;
                    item.min = min > 9 ? min : "0" + min;
                    item.sec = sec > 9 ? sec : "0" + sec;
                    if (item.status == 1) {
                        item.status_word = '考试中';
                    }else if(item.status == 3) {
                        item.status_word = '阅卷中';
                    }else{
                        item.status_word = '已结束';
                    }
                });
                if (list.length > 0) {
                    this.list.push(...list);
                }
                console.log(this.list)
                setTimeout(() => {
                    this.loading = false;
                    if (list.length < this.pagination.size) {
                        this.over = true;
                    }
                }, 200);
            });
        },
        ScrollToBottomEvt() {
            const el = document.getElementById("content");
            const toBottom = el.scrollHeight - window.screen.height - el.scrollTop;
            if (toBottom < 10) {
                this.getData(true);
            }
        },
        goResult(id) {
            this.$router.push({
                name: "AnswerExamsResult",
                query: {
                    id: this.id,
                    pid: id,
                    back: -1
                },
            });
        }
    }
}
</script>
<style lang="less" scoped>
.list {
    margin-top: 60px;
}
.none {
    margin-top: 120px;
}
.drop {
  display: inline-block;
  width: 100%;
  text-align: center;
  color: #cccccc;
  font-size: 14px;
  margin-top: 30px;
  margin-bottom: 30px;
}
</style>