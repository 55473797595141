<!-- (c) 智汇考题 -->
<template>
    <div class="navheader borderbox" style="border-bottom:none;">
      <img
        class="back"
        @click="goBack()"
        src="../assets/img/back-b.png"
      />
      <div class="title">{{title}}</div>
    </div>
</template>
<script>
export default {
  props: ["title"],
}
</script>